import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { postData } from './actions';

import { CoreHeadingBlock } from '../blocks/CoreHeadingBlock';
import { CoreParagraphBlock } from '../blocks/CoreParagraphBlock';

import styles from './Form.styles';

const useStyles = makeStyles(theme => styles(theme));

function QuoteBuilder({ products }) {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState();
  const [company, setCompany] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [comments, setComments] = useState('');
  const [formLocked, lockForm] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const productData = products.map(product => {
      return `${product.data.title}/${product.code}`;
    }).join('<br>');

    const request = postData({
      forename: name,
      surname,
      email,
      telephone: tel,
      company,
      company_postcode: postcode,
      country,
      comments,
      products: productData,
    });

    request.then(res => {
      if (res.status === 200 && res.data.success === true) {
        lockForm(true);
      }
    })
  }

  return <div className="enquiry-form">
    {formLocked && <p className={classes.success}>Thank you. A member of the team will get back to your shortly.</p>}
    {!formLocked && <CoreHeadingBlock
      attributes={{
        align: "",
        anchor: "",
        content: "Submit your list",
        level: 5,
        textColor: "foreground_primary",
        backgroundColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />}

    {!formLocked && <CoreParagraphBlock
      attributes={{
      align: "",
      content: "Submit your list to us and we’ll endeavour to provide a quote by the next working day. ",
      textColor: "foreground_primary",
      backgroundColor: "",
        __typename: "WpCoreParagraphBlockAttributes",
      }}
      innerBlocks={[]}
    />}

    {!formLocked && <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
      <fieldset>

        <label for="name">First Name*</label>
        <input type="text" name="name" placeholder="John" required onChange={(e) => setName(e.target.value)} />

        <label for="surname">Last Name*</label>
        <input type="text" name="surname" placeholder="Smith" required onChange={(e) => setSurname(e.target.value)} />

        <label for="email">Email*</label>
        <input type="email" name="email" placeholder="example@gmail.com" required onChange={(e) => setEmail(e.target.value)} />

        <label for="phone">Phone Number*</label>
        <input type="tel" name="phone" placeholder="0778 8911 339" required onChange={(e) => setTel(e.target.value)} />

        <label for="company">Company Name</label>
        <input type="text" name="company" placeholder="Bosch" onChange={(e) => setCompany(e.target.value)} />

        <label for="postcode">Company Postcode</label>
        <input type="text" name="postcode" placeholder="SY7 9QS" onChange={(e) => setPostcode(e.target.value)} />

        <label for="country">Country</label>
        <input type="text" name="country" placeholder="United Kingdom" onChange={(e) => setCountry(e.target.value)} />

        <label for="comments">Comments</label>
        <textarea name="comments" placeholder="Comments here..." onChange={(e) => setComments(e.target.value)}/>

        <input type="submit" value="Submit for quote" id="QuoteBuilderSubmit" />

        <p>*Field is required</p>
      </fieldset>

    </form>}

  </div>;
}

export default QuoteBuilder;
