import buttonArrow from "../../images/button-arrow.png";
import pdfIcon from "../../images/pdf-download.png";

export default theme => ({
    header : {
        marginTop: 40,
        marginBottom: 24,
    },
    button: {
        display: 'flex',
        margin: '24px 0 24px auto',
    },
    productListing: {
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& .listing': {
                flex: '0 0 56.25%'
            },
            '& .enquiry-form': {
                flex: '0 0 31.25%'
            }
        },
        '& .products': {
            borderTop: '2px solid ' + theme.palette.primary.main + '4D',
        },
        '& .actions': {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: 12,
            textAlign: 'right',
            '& .download': {
                borderRadius: 26,
                fontFamily: theme.bodyFont,
                fontSize: 18,
                letterSpacing: '0.008em',
                lineHeight: 2,
                fontWeight: 700,
                border: 'none',
                padding: '10px 24px',
                marginTop: 24,
                marginRight: 24,
                position: 'relative',
                display: 'inline-table',
                textDecoration: 'none',
                height: 52,
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                boxShadow: '0 24px 26px rgba(0,0,0,0.16)',
                transition: 'background-color .3s ease-in-out',
                '&::after': {
                    content: '""',
                    display: 'inline-block',
                    marginLeft: 12,
                    width: 22,
                    verticalAlign: 'text-top',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(' + pdfIcon + ')',
                    backgroundSize: 'cover',
                    height: 22,
                },
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.dark,
                }
            }
        },
        '& .enquiry-form': {
            padding: '32px 0',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                padding: '50px 0',
            },
            '&::before': {
                content: '""',
                display: 'block',
                top: 0,
                bottom: 0,
                left: '50%',
                width: '100vw',
                transform: 'translateX(-50%)',
                position: 'absolute',
                backgroundColor: theme.palette.primary.main,
                zIndex: -1,
                [theme.breakpoints.up('lg')]: {
                    left: '-20%',
                    transform: 'none',
                }
            },
            '& h5, & p': {
                marginBottom: 24,
            },
            '& fieldset': {
                border: 'none',
                padding: 0,
                '& label': {
                    color: theme.palette.primary.contrastText,
                    fontSize: 10,
                    lineHeight: 1.6,
                    letterSpacing: '0.0152em',
                    textTransform: 'uppercase',
                    margin: 0,
                    fontFamily: theme.bodyFont,
                },
                ' & input, textarea': {
                    fontFamily: theme.bodyFont,
                    backgroundColor: 'transparent',
                    borderRadius: 24,
                    fontSize: 16,
                    lineHeight: 1.5,
                    padding: '12px 30px',
                    border: '1px solid ' + theme.palette.primary.contrastText,
                    marginBottom: 24,
                    color: theme.palette.primary.contrastText,
                    outline: 'none',
                },
                '& input[type="submit"]': {
                    width: 'auto',
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    fontSize: 18,
                    display: 'flex',
                    margin: '0 0 0 auto',
                    padding: '10px 62px 8px 30px',
                    backgroundImage: 'url(' + buttonArrow + ')',
                    backgroundPosition: 'center right 24px',
                    backgroundRepeat: 'no-repeat',
                    outline: 'none',
                    boxShadow: '0 8px 10px rgb(0 0 0 0.8)',
                    cursor: 'pointer',
                }
            }
        }
    },
    product: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderBottom: '2px solid ' + theme.palette.primary.main + '4D',
        padding: '40px 0',
        justifyContent: 'space-between',
        '& > button': {
            width: 22,
            height: 22,
            border: 'none',
            outline: 'none',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main + '14',
            fontFamily: theme.bodyFont,
            color: theme.palette.primary.main,
            lineHeight: '22px',
            padding: 0,
            textAlign: 'center',
            marginRight: 12,
        },
        '& img': {
            width: 80,
            [theme.breakpoints.up('md')]: {
                width: 130,
            }
        },
        '& .placeholder': {
            backgroundColor: theme.palette.primary.main
        },
        '& .product-info-link': {
            flex: '0 0 calc(100% - 230px)',
            maxWidth: 'calc(100% - 230px)',
            paddingLeft: 12,
        },
        '& .product-info': {
            '& .title': {
                fontWeight: 'bold',
                letterSpacing: '0.008em',
                lineHeight: 2,
                fontSize: 18,
                color: theme.palette.primary.main,
                display: 'block',
            },
            '& .code': {
                color: theme.palette.primary.main,
                opacity: .3,
                fontSize: 16,
                letterSpacing: '0.032em',
                lineHeight: 1.5,
                display: 'block',
            }
        }
    },
    imageLinkRow: {
        marginBottom: 36,
        marginTop: 36,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 140,
            paddingBottom: 80,
        }
    }
});